import React from "react"
import { graphql } from "gatsby"
import Layout from "../../modules/layout"
import SEO from "../../modules/seo"
import BigHeader from "../../components/bigHeader"
import RoomList from "../../components/roomList"
import QuickSelect from "../../components/quickSelect"

const BendestorfBuchen = ({ data: { allContentfulBuchen } }) => {
  console.log(allContentfulBuchen.nodes[0])
  const content = allContentfulBuchen.nodes[0]

  return (
    <Layout theme="strandhotel">
      <SEO title={content.title} />
      <BigHeader 
        image={content.headerImage.fluid} 
        alt={content.headerImage.description} 
        additionalClass="home"
        title={content.title}
        subTitle={content.subTitle}
      />
      <QuickSelect />
      <RoomList />
    </Layout>
  )
}

export const pageQuery = graphql`
query strandhotelBuchenQuery {
  allContentfulBuchen(filter: {type: {eq: "Strandhotel"}}) {
    nodes {
      id
      title
      subTitle
      headerImage {
        fluid(maxWidth: 1600 quality: 80) {
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
          aspectRatio
        }
        description
      }
    }
  }
}
`

export default BendestorfBuchen
